<template>
  <v-container fluid class="down-top-padding pt-0">
    <v-row>
      <v-col cols="12">
        <div>
          <change-password
            v-if="changePasswordDialog"
            userType="member"
            :selectedUser="selectedMember"
            :dialogStatus="changePasswordDialog"
            @close="changePasswordDialog = false"
            @refresh="initialize(1)"
          ></change-password>
          <create-and-edit-associate
            v-if="associateDialog"
            :selectedMember="selectedMember"
            :dialogStatus="associateDialog"
            @close="associateDialog = false"
            @refresh="initialize(1)"
          ></create-and-edit-associate>
          <create-and-edit-member
            v-if="dialog"
            :selectedMemberData="selectedMember"
            :dialogStatus="dialog"
            @close="dialog = false"
            @refresh="initialize(1)"
            ref="createAndEditForm"
          ></create-and-edit-member>
          <confirmation-dialog
            @close="dialogDelete = false"
            @confirm="deleteItemConfirm"
            :dialog="dialogDelete"
            :dialogHeadline="deleteConfirmationHeadline"
          ></confirmation-dialog>
          <clear-due-payment
            v-if="clearDueDialog"
            :selectedUser="selectedMember"
            :dialogStatus="clearDueDialog"
            @close="clearDueDialog = false"
            @refresh="initialize(1)"
          ></clear-due-payment>
          <v-data-table
            :loading="loading"
            item-key="id"
            class="elevation-0 pb-4 pt-4 members_list_table"
            :headers="headers"
            :items="members"
            :expanded.sync="expanded"
            show-expand
            :page.sync="page"
            :custom-sort="customSort"
            :items-per-page="itemsPerPage"
            hide-default-footer
          >
            <template v-slot:top class="px-3">
              <v-toolbar flat class="mb-4 px-3">
                <v-text-field
                  outlined
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('member.search')"
                  @input="searchMembers(1)"
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-select
                  outlined
                  v-model="payment_method"
                  :items="paymentMethods"
                  item-text="name"
                  item-value="id"
                  :label="$t('member.paymentMethod')"
                  hide-details="auto"
                  :placeholder="$t('member.paymentMethod')"
                  @change="initialize(1)"
                  return-value
                  :clearable="true"
                ></v-select>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="manageUser"
                  color="primary"
                  dark
                  large
                  dense
                  @click="createItem"
                  elevation="4"
                >
                  {{ $t("member.addMember") }}
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col cols="12"> </v-col>
                  <v-col cols="4"
                    ><b>{{ $t("member.companyEmail") }}</b> :
                    {{ item.user_detail.company_email }}
                  </v-col>
                  <v-col cols="4"
                    ><b>{{ $t("member.companyPhone") }}</b> :
                    {{ item.user_detail.company_phone }}
                  </v-col>
                  <v-col cols="4"
                    ><b>{{ $t("member.language") }}</b> :
                    {{ item.user_detail.language }}
                  </v-col>
                  <v-col cols="4"
                    ><b>{{ $t("member.licensed") }}</b> :
                    {{
                      item.user_detail.licensed
                        ? $t("member.yes")
                        : $t("member.no")
                    }}
                  </v-col>
                  <v-col cols="4"
                    ><b>{{ $t("member.timezone") }}</b> :
                    {{ item.user_detail.timezone }}
                  </v-col>
                  <v-col cols="4"
                    ><b>{{ $t("member.services") }}</b> :
                    {{ getServices(item.services) }}
                  </v-col>
                  <v-col cols="4"
                    ><b>{{ $t("member.room") }}</b> :
                    {{ getRooms(item.rooms) }}
                  </v-col>
                  <v-col cols="4"
                    ><b>{{ $t("member.address") }}</b> :
                    {{ item.user_detail.address }},
                    {{ item.user_detail.state }},
                    {{ item.user_detail.zip_code }}
                  </v-col>
                </v-row>
              </td>
            </template>
            <template v-slot:item.created_at="{ item }">
              {{ item.created_at | datefilter }}
            </template>
            <template v-if="manageUser" v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon v-bind="attrs" v-on="on">
                    <v-icon color="primary">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    @click="
                      selectItem(item);
                      associateDialog = true;
                    "
                    ><v-icon color="#1b5e20" medium>mdi-plus</v-icon
                    >{{ $t("member.addAssociate") }}</v-list-item
                  >
                  <v-list-item
                    @click="
                      selectItem(item);
                      dialog = true;
                    "
                    ><v-icon color="#1b5e20" medium>mdi-pencil</v-icon
                    >{{ $t("member.update") }}</v-list-item
                  >
                  <v-list-item
                    @click="
                      selectedMember = item;
                      dialogDelete = true;
                    "
                    ><v-icon color="#1b5e20" medium>mdi-delete</v-icon
                    >{{ $t("member.delete") }}</v-list-item
                  >
                  <v-list-item>
                    <v-switch
                      v-model="item.is_active"
                      :label="
                        item.is_active
                          ? $t('member.block')
                          : $t('member.unblock')
                      "
                      @change="activation(item)"
                    ></v-switch>
                  </v-list-item>
                  <v-list-item
                    @click="
                      selectedMember = item;
                      getPaymentHistoryById();
                    "
                    ><v-icon color="#1b5e20" medium>mdi-currency-usd</v-icon
                    >{{ $t("member.paymentHistory") }}</v-list-item
                  >
                  <v-list-item
                    v-if="
                      item.current_status == 'unpaid' &&
                      item.payment_method &&
                      (item.payment_method.name == 'Cash' ||
                        item.payment_method.name == 'Cheque')
                    "
                    @click="selectItem(item); clearDueDialog=true;"
                    ><v-icon color="#1b5e20" medium>mdi-pencil</v-icon
                    >{{ $t("member.clearDue") }}</v-list-item
                  >
                  <v-list-item
                    @click="
                      selectedMember = item;
                      getAssociateListById();
                    "
                    ><v-icon color="#1b5e20" medium>mdi-account</v-icon
                    >{{ $t("member.seeAssociates") }}</v-list-item
                  >
                  <v-list-item
                    @click="
                      selectItem(item);
                      changePasswordDialog = true;
                    "
                    ><v-icon color="#1b5e20" medium>mdi-account-key</v-icon
                    >{{ $t("member.changePassword") }}</v-list-item
                  >
                </v-list>
              </v-menu>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize(1)"> Reset </v-btn>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-if="members.length > 0"
              v-model="meta.current_page"
              :length="meta.last_page"
              @input="initialize"
            ></v-pagination>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmationDialog from "../components/common/ConfirmationDialog";
import CreateAndEditMember from "../components/members/CreateAndEditMember";
import CreateAndEditAssociate from "../components/members/CreateAndEditAssociate";
import ClearDuePayment from "../components/financial/ClearDuePayment";
import { VIEW_USER, MANAGE_USER } from "@/constants/permission";
import _ from "lodash";
import { showSnackbar } from "../eventbus/action.js";
import ChangePassword from "../components/common/ChangePassword";
export default {
  name: "Members",
  components: {
    ChangePassword,
    ConfirmationDialog,
    CreateAndEditMember,
    CreateAndEditAssociate,
    ClearDuePayment
  },
  data() {
    return {
      payment_method: null,
      manageUser: false,
      viewUser: false,
      dialog: false,
      associateDialog: false,
      changePasswordDialog: false,
      expanded: [],
      page: 1,
      pageCount: 2,
      itemsPerPage: 50,
      dialogDelete: false,
      clearDueDialog: false,
      search: "",
      headers: [
        { text: "", value: "data-table-expand", width: "4%" },
        {
          text: this.$t("member.id"),
          align: "start",
          value: "id",
          width: "7%",
        },
        {
          text: this.$t("member.joinDate"),
          value: "created_at",
          width: "10%",
        },
        {
          text: this.$t("member.companyName"),
          value: "user_detail.company_name",
          width: "10%",
          sortable: false,
        },
        {
          text: this.$t("member.email"),
          value: "email",
          width: "20%",
        },
        // {
        //   text: this.$t("member.companyEmail"),
        //   value: "user_detail.company_email",
        // },
        {
          text: this.$t("member.password"),
          value: "p_token",
          width: "10%",
          sortable: false,
        },
        {
          text: this.$t("member.repName"),
          align: "start",
          value: "user_detail.representative_name",
          width: "10%",
          sortable: false,
        },
        {
          text: this.$t("member.repPhone"),
          value: "user_detail.representative_phone",
          width: "10%",
          sortable: false,
        },
        {
          text: this.$t("member.payment"),
          value: "user_subscription.amount",
          width: "10%",
          sortable: false,
        },
        {
          text: this.$t("member.paymentMethod"),
          value: "user_subscription.payment_method.name",
          width: "10%",
          sortable: false,
        },
        {
          text: this.$t("member.actions"),
          value: "actions",
          width: "10%",
          sortable: false,
        },
      ],
      previousIndex: [],
      previousOrder: [],
      selectedMember: {
        user_detail: {},
        user_subscription: {},
      },
      payload: {},
      defaultItem: {
        services: [
          { id: 10, name: "Posting" },
          { id: 11, name: "Broadcasting" },
        ],
        user_detail: {
          language: "en",
          timezone: "America/Los_Angeles",
        },
        user_subscription: {},
      },
      loading: false,
      deleteConfirmationHeadline: this.$t("member.deleteHeadline"),
    };
  },
  computed: {
    ...mapGetters({
      members: "members/getMembers",
      meta: "members/getMeta",
      user: "user/getUser",
      paymentMethods: "paymentMethod/getPaymentMethods",
    }),
  },
  mounted() {
    this.initialize(1);
    this.getPaymentMethods();
  },

  methods: {
    ...mapActions({
      getMembers: "members/getMembers",
      deleteMember: "members/deleteMember",
      activateMember: "members/activateMember",
      setHeaderMenuItems: "common/setHeaderMenuItems",
      getPaymentMethods: "paymentMethod/getPaymentMethods",
    }),

    async initialize(page) {
      this.page = page;
      let menuItems = [
        { title: this.$t("commonLayout.members"), path: "/pages/members" },
        {
          title: this.$t("commonLayout.associates"),
          path: "/pages/associates",
        },
        {
          title: this.$t("commonLayout.coreBuying"),
          path: "/pages/core-buying",
        },
      ];
      const userPermission = this.user.permission.map((val) => val.name);
      this.manageUser = userPermission.includes(MANAGE_USER);
      this.viewUser = userPermission.includes(VIEW_USER);
      if (!this.viewUser) {
        menuItems = [];
      }
      this.setHeaderMenuItems(menuItems);
      this.loading = true;
      try {
        await this.getMembers({
          search: this.search,
          page: this.page,
          per_page: this.itemsPerPage,
          payment_method: this.payment_method,
          ...this.payload,
        });
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showSnackbar(errorMessage);
      } finally {
        this.loading = false;
      }
    },
    searchMembers: _.debounce(function (page) {
      this.initialize(page);
    }, 500),

    selectItem(item) {
      this.selectedMember = _.cloneDeep(item);
    },

    createItem() {
      this.selectedMember = _.cloneDeep(this.defaultItem);
      this.dialog = true;
    },

    getAssociateListById() {
      this.$router.push({
        path: "associates",
        query: { id: this.selectedMember.id },
      });
    },

    getPaymentHistoryById() {
      this.$router.push({
        path: "payment-history",
        query: { id: this.selectedMember.id },
      });
    },

    getServices(data) {
      return data.map((val) => val.name).join(", ");
    },

    getRooms(data) {
      return data && data.map((val) => val.name).join(", ");
    },

    customSort(items, index, isDescending) {
      if (
        _.isEqual(this.previousIndex, index) &&
        _.isEqual(this.previousOrder, isDescending)
      ) {
        return items;
      }
      this.previousIndex = index;
      this.previousOrder = isDescending;
      this.payload = {};
      if (isDescending.length && isDescending[0]) {
        this.payload = { ...this.payload, sortOrder: "desc", sortBy: index[0] };
      } else if (isDescending.length && !isDescending[0]) {
        this.payload = { ...this.payload, sortOrder: "asc", sortBy: index[0] };
      }
      this.initialize(this.page);
      return this.members;
    },

    async deleteItemConfirm() {
      this.loading = true;
      try {
        await this.deleteMember({
          id: this.selectedMember.id,
        });
        this.dialogDelete = false;
        this.initialize(1);
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showSnackbar(errorMessage);
      } finally {
        this.loading = false;
      }
    },
    async activation(member) {
      this.loading = true;
      try {
        await this.activateMember({
          id: member.id,
        });
        this.dialogDelete = false;
        this.initialize(this.page);
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showSnackbar(errorMessage);
      } finally {
        this.loading = false;
      }
    },
  },

  destroyed() {
    this.setHeaderMenuItems([]);
  },
};
</script>
